
const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i)
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry|BB10/i)
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i)
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i)
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    )
  },
  getOsName: function () {
    var osName = 'Unknown OS'
    if (isMobile.Android()) {
      osName = 'Android'
    }
    if (isMobile.BlackBerry()) {
      osName = 'BlackBerry'
    }
    if (isMobile.iOS()) {
      osName = 'iOS'
    }
    if (isMobile.Opera()) {
      osName = 'Opera Mini'
    }
    if (isMobile.Windows()) {
      osName = 'Windows'
    }
    return {
      osName,
      type: 'mobile'
    }
  }
}

// 判断值是否存在
export function hasVal(val) {
  return val !== undefined && val !== null && ((typeof val === 'string' && val !== '' && val !== '-6847833900000') || (typeof val === 'number' && val !== -6847833900000) || (typeof val === 'object' && JSON.stringify(val) !== '{}' && JSON.stringify(val) !== '[]' && JSON.stringify(val) !== '""'))
}

/**
 * 每三位分割数字
 * @param {数字} val
 * @returns
 */
export function thousands(val) {
  if (hasVal(val)) {
    var str = val.toString()
    var reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
    return str.replace(reg, '$1,')
  } else {
    return '0'
  }
}

export function parseTime(time, cFormat) {
  if (time === null || time <= 0 || arguments.length === 0 || time === undefined) {
    return '-'
  }
  if (time === '-6847833900000') return '-'
  if (!(/^-?\d+$/.test(time.toString()))) { return time }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^-?[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

// 将数字通过千位分隔符分隔
export function separate(num) {
  num = num.toString().split('.') // 分隔小数点
  var arr = num[0].split('').reverse() // 转换成字符数组并且倒序排列
  var res = []
  for (var i = 0, len = arr.length; i < len; i++) {
    if (i % 3 === 0 && i !== 0) {
      res.push(',') // 添加分隔符
    }
    res.push(arr[i])
  }
  res.reverse() // 再次倒序成为正确的顺序
  if (num[1]) {
    // 如果有小数的话添加小数部分
    res = res.join('').concat('.' + num[1])
  } else {
    res = res.join('')
  }
  return res
}

// 对数组进行分页(页码、条数，待分页的数组)
export function paginationFun(pageNo, pageSize, array) {
  const offset = (pageNo - 1) * pageSize
  return (offset + pageSize >= array.length) ? array.slice(offset, array.length) : array.slice(offset, offset + pageSize)
}

/**
 * 根据日期字符串获取星期几
 * @param dateString 日期字符串（如：2020-05-02）
 * @returns {String}
 */
export function getWeek(dateString) {
  var dateArray = dateString.split('-')
  var date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2])
  return '星期' + '日一二三四五六'.charAt(date.getDay())
}

export function inArray(array, name, value) {
  if (isFunction(name)) {
    return array.findIndex(name) > -1
  } else {
    let model = []
    if (typeof value === 'undefined') {
      model = array.filter(element => {
        return name === element
      })
    } else {
      model = array.filter(element => {
        return value === element[name]
      })
    }
    return typeof model !== 'undefined' && model.length > 0
  }
}

export function getFirstOrDefault(array, action) {
  if (hasVal(array)) {
    const model = array.filter(action)
    if (hasVal(model)) { return model[0] }
  }
  return null
}

export function isNotNull(val) {
  return val !== undefined && val !== null
}

export function notNull(val) {
  return isNotNull(val)
}

export function isNull(val) {
  return val === undefined || val === null
}

export function isFunction(val) {
  return val !== undefined && val !== null && typeof val === 'function'
}

export function isPC() {
  var userAgentInfo = navigator.userAgent
  var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
  var flag = true
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}

export function throttle(func, delay) {
  var timer = null
  var startTime = Date.now()
  return function () {
    var curTime = Date.now()
    var remaining = delay - (curTime - startTime)
    var context = this
    var args = arguments
    clearTimeout(timer)
    if (remaining <= 0) {
      func.apply(context, args)
      startTime = Date.now()
    } else {
      timer = setTimeout(() => {
        console.log('duplicate click')
      }, remaining)
    }
  }
}

export function getBrowser() {
  var sys = {}
  var ua = navigator.userAgent.toLowerCase()
  var s;
  (s = ua.match(/edge\/([\d.]+)/))
    ? (sys.edge = s[1])
    : (s = ua.match(/rv:([\d.]+)\) like gecko/))
      ? (sys.ie = s[1])
      : (s = ua.match(/msie ([\d.]+)/))
        ? (sys.ie = s[1])
        : (s = ua.match(/firefox\/([\d.]+)/))
          ? (sys.firefox = s[1])
          : (s = ua.match(/tbs\/([\d]+)/))
            ? (sys.tbs = s[1])
            : (s = ua.match(/xweb\/([\d]+)/))
              ? (sys.xweb = s[1])
              : (s = ua.match(/chrome\/([\d.]+)/))
                ? (sys.chrome = s[1])
                : (s = ua.match(/opera.([\d.]+)/))
                  ? (sys.opera = s[1])
                  : (s = ua.match(/version\/([\d.]+).*safari/))
                    ? (sys.safari = s[1])
                    : 0

  if (sys.xweb) return { browser: 'webView XWEB', version: '' }
  if (sys.tbs) return { browser: 'webView TBS', version: '' }
  if (sys.edge) return { browser: 'Edge', version: sys.edge }
  if (sys.ie) return { browser: 'IE', version: sys.ie }
  if (sys.firefox) return { browser: 'Firefox', version: sys.firefox }
  if (sys.chrome) return { browser: 'Chrome', version: sys.chrome }
  if (sys.opera) return { browser: 'Opera', version: sys.opera }
  if (sys.safari) return { browser: 'Safari', version: sys.safari }

  return { browser: '', version: '0' }
}

export function isHidden() {
  var hidden, visibilityChange
  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }
  return document[hidden]
}

export function getIPAddress() {
  return new Promise(resolve => {
    window.RTCPeerConnection =
      window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection // compatibility for firefox and chrome
    const pc = new RTCPeerConnection({ iceServers: [] })
    const noop = function () { }
    let IPAddress = ''
    const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/
    pc.createDataChannel('') // create a bogus data channel
    pc.createOffer(pc.setLocalDescription.bind(pc), noop) // create offer and set local description
    // listen for candidate events
    pc.onicecandidate = function (ice) {
      if (
        !ice ||
        !ice.candidate ||
        !ice.candidate.candidate ||
        !ipRegex.exec(ice.candidate.candidate)
      ) {
        return
      }
      IPAddress = ipRegex.exec(ice.candidate.candidate)[1]
      pc.onicecandidate = noop
      resolve(IPAddress)
    }
  })
}

export function detectDesktopOS() {
  var unknown = '-'
  var nVer = navigator.appVersion
  var nAgt = navigator.userAgent
  var os = unknown
  var clientStrings = [
    {
      s: 'Chrome OS',
      r: /CrOS/
    },
    {
      s: 'Windows 10',
      r: /(Windows 10.0|Windows NT 10.0)/
    },
    {
      s: 'Windows 8.1',
      r: /(Windows 8.1|Windows NT 6.3)/
    },
    {
      s: 'Windows 8',
      r: /(Windows 8|Windows NT 6.2)/
    },
    {
      s: 'Windows 7',
      r: /(Windows 7|Windows NT 6.1)/
    },
    {
      s: 'Windows Vista',
      r: /Windows NT 6.0/
    },
    {
      s: 'Windows Server 2003',
      r: /Windows NT 5.2/
    },
    {
      s: 'Windows XP',
      r: /(Windows NT 5.1|Windows XP)/
    },
    {
      s: 'Windows 2000',
      r: /(Windows NT 5.0|Windows 2000)/
    },
    {
      s: 'Windows ME',
      r: /(Win 9x 4.90|Windows ME)/
    },
    {
      s: 'Windows 98',
      r: /(Windows 98|Win98)/
    },
    {
      s: 'Windows 95',
      r: /(Windows 95|Win95|Windows_95)/
    },
    {
      s: 'Windows NT 4.0',
      r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/
    },
    {
      s: 'Windows CE',
      r: /Windows CE/
    },
    {
      s: 'Windows 3.11',
      r: /Win16/
    },
    {
      s: 'Android',
      r: /Android/
    },
    {
      s: 'Open BSD',
      r: /OpenBSD/
    },
    {
      s: 'Sun OS',
      r: /SunOS/
    },
    {
      s: 'Linux',
      r: /(Linux|X11)/
    },
    {
      s: 'iOS',
      r: /(iPhone|iPad|iPod)/
    },
    {
      s: 'Mac OS X',
      r: /Mac OS X/
    },
    {
      s: 'Mac OS',
      r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/
    },
    {
      s: 'QNX',
      r: /QNX/
    },
    {
      s: 'UNIX',
      r: /UNIX/
    },
    {
      s: 'BeOS',
      r: /BeOS/
    },
    {
      s: 'OS/2',
      r: /OS\/2/
    },
    {
      s: 'Search Bot',
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
    }
  ]
  for (var i = 0, cs; (cs = clientStrings[i]); i++) {
    if (cs.r.test(nAgt)) {
      os = cs.s
      break
    }
  }
  var osVersion = unknown
  if (/Windows/.test(os)) {
    if (/Windows (.*)/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1]
    }
    os = 'Windows'
  }
  switch (os) {
    case 'Mac OS X':
      if (/Mac OS X (10[/._\d]+)/.test(nAgt)) {
        // eslint-disable-next-line no-useless-escape
        osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1]
      }
      break
    case 'Android':
      // eslint-disable-next-line no-useless-escape
      if (/Android ([\.\_\d]+)/.test(nAgt)) {
        // eslint-disable-next-line no-useless-escape
        osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1]
      }
      break
    case 'iOS':
      if (/OS (\d+)_(\d+)_?(\d+)?/.test(nAgt)) {
        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer)
        osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0)
      }
      break
  }
  return {
    osName: os + osVersion,
    type: 'desktop'
  }
}

export function getOS() {
  if (isMobile.any()) {
    return isMobile.getOsName()
  } else {
    return detectDesktopOS()
  }
}

/**
 * 获取当前时间(此方法请勿删除)
 */
export function getNowTime() {
  return new Date(+new Date() + 8 * 3600 * 1000).toJSON().substr(0, 19).replace('T', ' ')
}

/**
 * 根据当前时间获取距离某个时间段的时间(此方法请勿删除)
 */
export function getBeforTime(monthsTime) {
  return new Date(+new Date() + 8 * 3600 * 1000 - monthsTime).toJSON().substr(0, 19).replace('T', ' ')
}

