import { login } from '@/api/account'
import { getToken, removeToken, setToken, getProjectId, saveProjectId, removeProjectId } from '@/utils/auth'

const state = {
  token: getToken(),
  projectId: getProjectId() || '0',
  topDialogvisible: false
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },

  SET_PROJECT_ID: (state, projectId) => {
    state.projectId = projectId
    saveProjectId(projectId)
  },

  Clear_PROJECT_ID(state, projectId) {
    state.projectId = projectId
    removeProjectId()
  },

  ShowToppDialog: (state) => {
    state.topDialogvisible = true
  },

  HideToppDialog: (state) => {
    state.topDialogvisible = false
  }
}

const actions = {
  login({ commit }, userInfo) {
    const { name, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ name: name.trim(), password: password }).then(response => {
        // console.log('response', response)
        const { data } = response
        console.log('data', data)
        if (data.accessToken) {
          commit('SET_TOKEN', data.accessToken)
          setToken(data.accessToken)
        }
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken()
      resolve()
    })
  },

  setProjectId({ commit }, projectId) {
    commit('SET_PROJECT_ID', projectId)
  },

  clearProjectId({ commit }, projectId) {
    commit('Clear_PROJECT_ID', projectId)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

