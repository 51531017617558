import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BaiduMap from 'vue-baidu-map'
import EZUIKit from 'ezuikit-js'// 引入萤石云js
import elDragDialog from '@/utils/dialog'
Vue.directive('el-drag-dialog', elDragDialog)
import './plugins/element.js'
import 'lib-flexible' // 默认将屏幕划分为10等分 （如果设计稿尺寸为1920px, 则 1rem = (1920/10)px
import scroll from 'vue-seamless-scroll'
import VueCoreVideoPlayer from 'vue-core-video-player'
import dataV from '@jiaminghi/data-view'
import animated from 'animate.css/animate.compat.css'// 引入动画
import '@/assets/iconfont/iconfont.css'
import * as filters from './filters' // global filters
import './permission' // permission control
const ECharts = require('echarts')
Vue.prototype.$echarts = ECharts

Vue.use(BaiduMap, {
  ak: 'Mw1s4afkpdQODEegrXbuNtSNKcesCPLS'
})
Vue.use(scroll)
Vue.use(dataV)
Vue.use(VueCoreVideoPlayer)
Vue.use(animated)
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
