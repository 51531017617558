import request from '@/utils/request'

/** *
 * 获取天气
 */
export function getWeather(appkey) {
  return request({
    url: '/Weather',
    method: 'GET',
    params: {
      appkey: appkey
    }
  })
}
