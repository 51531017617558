import router from './router'
import store from './store'
import { getToken } from '@/utils/auth' // get token from cookie
// import NProgress from 'nprogress' // progress bar

const whiteList = ['/login']

// NProgress.configure({ showSpinner: false }) // NProgress Configuration
router.beforeEach(async (to, from, next) => {
  // NProgress.start()

  if (to.meta.title) {
    document.title = '物知智慧工地指挥中心'
  }

  const hasToken = getToken()

  if (hasToken) {
    next()
  } else {
    /* has no token*/
    const item = whiteList.filter(item => {
      return to.path.startsWith(item)
    })
    if (item !== undefined && item !== null && item.length > 0) {
      // in the free login whitelist, go directly
      next()
    } else {
      await store.dispatch('user/resetToken')
      // next(`/login?redirect=${to.path}`)
      next(`/login`)
      // NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  // NProgress.done()
})

