import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import owner from './modules/owner'
import project from './modules/project'
import trtc from './modules/trtc'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    owner,
    project,
    trtc
  },
  getters
})

export default store
