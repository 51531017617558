import request from '@/utils/request'

export function login(data) {
  return request({
    hostType: 'SITE',
    url: '/labor/Account/SignIn',
    method: 'post',
    data
  })
}

export function getBeidouToken(params) {
  return request({
    hostType: 'SITE',
    url: '/auth/Account/GetAccessToken',
    method: 'get',
    params
  })
}
