<template>
  <div
    v-if="visible"
    class="qhgq c-d"
  >
    <div class="qh-icon">
      <div class="xz">
        <el-image
          class="qh-image"
          :src="require('../assets/image/qh-icon3.png')"
          :fit="'cover'"
        />
      </div>
      <div class="qh-menu" :style="style">
        <div v-for="item in menus" :key="item.path" @click="go(item)">
          <el-image class="qhnm-image" :src="item.icon" :fit="'cover'" />
          <span class="ml-10">{{ item.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 项目中心显示否
    homeShow: {
      type: Boolean,
      default: false
    },
    // 全景监控显示否
    videoShow: {
      type: Boolean,
      default: false
    },
    // 机械与设备显示否
    equipmentShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      path: '',
      visible: false,
      menus: [],
      style: {
        width: '320px',
        maxWidth: '320px'
      }
    }
  },
  watch: {
    $route(to, from) {
      this.path = to.path
      this.setMenus()
    }
  },
  created() {
    this.path = location.pathname
    this.setMenus()
  },
  methods: {
    setMenus() {
      this.visible = this.path !== '/home' && this.path !== '/login' && this.path !== '/'
      this.menus = [];
      [{
        icon: require('../assets/image/qhnm-icon1.png'),
        title: '项目中心',
        path: '/project'
      }, {
        icon: require('../assets/image/qhnm-icon2.png'),
        title: '全景监控',
        path: '/monitor'
      }, {
        icon: require('../assets/image/qhnm-icon3.png'),
        title: '机械与设备',
        path: '/machine'
      }].forEach(item => {
        if (item.path !== this.path) {
          this.menus.push(item)
        }
      })
      const width = (120 * this.menus.length + 90) + 'px'
      this.style.width = width
      this.style.maxWidth = width
    },
    go(item) {
      this.$router.push(item.path)
    }
  }
}
</script>

<style lang="scss" scoped>
.qhgq {
  position: fixed;
  right: 15px;
  bottom: 5px;
  width: 98px;
  height: 98px;
  background: url("../assets/image/qhgq.png") no-repeat center top;
  background-size: cover;
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qh-icon {
  margin-top: 4px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: linear-gradient(#00ffff, #00b7ee, #0068b7);
  padding: 15px;
  box-shadow: 0px 0px 10px rgba(0, 255, 255, 0.5);
}
.qh-image {
  width: 40px;
  height: 40px;
  opacity: 0.8;
  animation: turn 3s linear infinite;
}
@keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(90deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(270deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

.qhgq:hover .qh-menu {
  display: block;
  display: flex;
  animation: wd 1s;
}
.qh-menu {
  background-image: linear-gradient(#00ffff, #00b7ee, #0068b7);
  border-radius: 40px;
  height: 60px;
  position: fixed;
  right: 90px;
  bottom: 20px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  display: none;
  transition: width 1s;
  margin-right: 12px;
}
.qh-menu > div {
  width: 120px;
  cursor: pointer;
  font-size: 15px;
  font-family: "微软雅黑";
  color: #fff;
  margin: 0 5px;
  text-align: center;
  padding: 4px 10px;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 40px;
  background-image: linear-gradient(
    rgba(0, 255, 255, 0.2) 1%,
    rgba(0, 255, 255, 0.05) 60%,
    rgba(0, 255, 255, 0) 60%
  );
  animation: tmd 0.5s;
  animation-delay: 0s;
  opacity: 0;
  animation-fill-mode: both;
}
.qh-menu > div:hover {
  opacity: 1;
  background-color: rgba(1, 21, 46, 0.2);
}

.qhnm-image {
  width: 28px;
  height: 28px;
}

@keyframes wd {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes tmd {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
