import { saveOwner, getOwner } from '@/utils/auth'
import { getOwnerHome ,getOwnerInfo, getOwnerAttendRate, getWorkers, getGroupDevice, getGrpupSalaryInfo, getPrjSalaryStats, getOwnerAlarmLog } from '@/api/owner'

const state = {
  ownerInfo: getOwner() || {}, // 企业信息
  attendance: 0, // 出勤率
  workers: {}, // 出勤率、培训率、特殊工种统计
  groupdevices: [], // 设备状态
  grpupSalaryInfo: {}, // 开累应付、开累实付、开累未付工资统计
  PrjSalaryStatsList: [], // 企业关联项目工资数据列表
  ownerAlarmLogList: []// 企业关联设备报警列表
}

const mutations = {
  SET_OWNERINFODATAS(state, ownerInfo) {
    state.ownerInfo = ownerInfo
    saveOwner(state.ownerInfo)
  },
  SET_OWNERATTENDRATEDATAS(state, attendance) {
    state.attendance = Math.round((attendance * 100))
  },
  SET_WORKERSDATAS(state, workers) {
    workers.totalOneNum = Math.round((workers.totalOneNum * 100))
    workers.totalThreeNum = Math.round((workers.totalThreeNum * 100))
    workers.totalTwoNum = Math.round((workers.totalTwoNum * 100))
    state.workers = workers
  },
  SET_GROUPDEVICES(state, groupdevices) {
    state.groupdevices = groupdevices
  },
  SET_GRPUPSALARYINFO(state, grpupSalaryInfo) {
    state.grpupSalaryInfo = grpupSalaryInfo
  },
  SET_PRJSALARYSATS(state, PrjSalaryStatsList) {
    state.PrjSalaryStatsList = PrjSalaryStatsList
  },
  SET_OWNERALARMLOGLIST(state, ownerAlarmLogList) {
    state.ownerAlarmLogList = ownerAlarmLogList
  }
}

const actions = {
  getData({ commit }){
    return new Promise((resolve, reject) => {
      getOwnerHome().then(res => {
        const { data } = res
        commit('SET_OWNERINFODATAS', data.ownerInfo)
        commit('SET_OWNERATTENDRATEDATAS', data.attendRate)
        commit('SET_WORKERSDATAS', data.workers)
        commit('SET_GROUPDEVICES', data.groupDevices)
        commit('SET_GRPUPSALARYINFO', data.ownerSalary)
        commit('SET_PRJSALARYSATS', data.projectSalaries)
        commit('SET_OWNERALARMLOGLIST', data.deviceAlarms)
        resolve()
      })
    })
  },
  /**
     * 获取企业信息
     */
  getOwnerInfoDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getOwnerInfo().then(res => {
        const { data } = res
        commit('SET_OWNERINFODATAS', data)
        resolve()
      })
    })
  },
  /**
     * 获取企业管理人员出勤率
     */
  getOwnerAttendRateDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getOwnerAttendRate().then(res => {
        const { data } = res
        commit('SET_OWNERATTENDRATEDATAS', data.value)
        resolve()
      })
    })
  },
  /**
     * 获取企业建筑工人出勤率、培训率、特殊工种统计
     */
  getWorkersDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getWorkers().then(res => {
        const { data } = res
        commit('SET_WORKERSDATAS', data)
        resolve()
      })
    })
  },
  /**
     * 获取设备状态管理
     */
  getGroupDeviceDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getGroupDevice().then(res => {
        const { data } = res
        commit('SET_GROUPDEVICES', data.items)
        resolve()
      })
    })
  },
  /**
     * 获取开累应付、开累实付、开累未付工资统计
     */
  getGrpupSalaryInfoDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getGrpupSalaryInfo().then(res => {
        const { data } = res
        commit('SET_GRPUPSALARYINFO', data)
        resolve()
      })
    })
  },
  /**
     * 获取企业关联项目工资数据列表
     */
  getPrjSalaryStatsList({ commit }) {
    return new Promise((resolve, reject) => {
      getPrjSalaryStats().then(res => {
        const { data } = res
        commit('SET_PRJSALARYSATS', data.items)
        resolve()
      })
    })
  },
  /**
     * 获取企业关联设备报警列表
     */
  getOwnerAlarmLogList({ commit }) {
    return new Promise((resolve, reject) => {
      getOwnerAlarmLog().then(res => {
        const { data } = res
        commit('SET_OWNERALARMLOGLIST', data.items)
        resolve()
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
