import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  // withCredentials: true, // 跨域请求时发送 cookies
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (config.hostType && config.hostType === 'SITE') {
      config.baseURL = process.env.VUE_APP_SITE_API
      delete config.hostType
    } else if (config.hostType && config.hostType === 'BILOG') {
      config.baseURL = process.env.VUE_APP_BILOG_API
      delete config.hostType
    }
    // Do something before request is sent
    config.headers.post['Content-Type'] = 'application/json; charset=utf-8'
    config.headers.common['ClientType'] = 'PC'
    config.headers.common['Version'] = '1.0.0'
    config.headers.common['AppType'] = 'SmartBI'
    if (store.getters.token) {
      // 让每个请求携带token-- ['Authorization']为自定义key 请根据实际情况自行修改
      config.headers.common['Authorization'] = 'Bearer ' + getToken()
    }
    if (
      store.getters.projectId &&
      store.getters.projectId !== '' &&
      store.getters.projectId !== '0'
    ) {
      config.headers.common['ProjectId'] = store.getters.projectId
    }
    return config
  },
  (error) => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get information such as headers or status
   * Please return  response => response
   */
  /**
   * 下面的注释为通过在response里，自定义code来标示请求状态
   * 当code返回如下情况则说明权限有问题，登出并返回到登录页
   * 如想通过 XMLHttpRequest 来状态码标识 逻辑可写在下面error中
   * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
   */
  (response) => {
    if (response.config.responseType === 'blob') {
      return response.data
    }
    const res = response.data
    if (response.config.url === '/Weather') return res
    if (res.errorCode !== 0) { // res.code !== 20000
      Message({
        message: "请求失败，请刷新页面",
        type: 'error',
        duration: 2000
      })

      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      // if (res.errorCode === 401 || res.errorCode === 403 || res.errorCode === 405 || res.errorCode === 406 || res.errorCode === 407) { // res.code === 50008 || res.code === 50012 || res.code === 50014
      //   MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
      //     confirmButtonText: '重新登录',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     store.dispatch('user/resetToken').then(() => {
      //       location.reload() // 为了重新实例化vue-router对象 避免bug
      //     })
      //   })
      // } else if (res.errorCode === 2002) {
      //   Message({
      //     message: res.paramErrors && res.paramErrors.length > 0 && res.paramErrors[0].errorMessages && res.paramErrors[0].errorMessages.length > 0 && res.paramErrors[0].errorMessages[0] ? res.paramErrors[0].errorMessages[0] : res.message,
      //     type: 'error',
      //     duration: 2000
      //   })
      // }
      return Promise.reject('error')
    } else {
      return res
    }
  },
  (error) => {
    console.log('err' + error) // for debug
    Message({
      message: "请求失败，请刷新页面",
      type: 'error',
      duration: 2000
    })
    return Promise.reject(error)
  }
)

export default service
