import TRTC from 'trtc-js-sdk'
import { isFunction, isHidden } from '@/utils/index'
// eslint-disable-next-line no-unused-vars
/* global $ TRTC getCameraId getMicrophoneId resetView isHidden shareUserId addMemberView removeView addVideoView setAnimationFrame clearAnimationFrame*/
class RtcClient {
  constructor(options) {
    this.title = options.title || '可视对讲'
    this.mode = options.mode
    this.vue = options.vue
    this.sdkAppId_ = options.sdkAppId
    this.userId_ = options.userId
    this.userSig_ = options.userSig
    this.roomId_ = options.roomId
    this.prefix = options.prefix ? options.prefix : ''
    this.controls = options.controls

    // callback
    this.reset = options.reset
    this.addMember = options.addMember
    this.removeMember = options.removeMember
    this.addVideo = options.addVideo
    this.changeAudioStatus = options.changeAudioStatus // { userId, status} // userId：人员 Id，status：是否禁音
    this.changeVideoStatus = options.changeVideoStatus // { userId, status, streamId} // userId：人员 Id，status：是否禁用视频，streamId：视频流 Id
    this.loaded = options.loaded // 视频画面加载完成
    this.changeAudioVolume = options.changeAudioVolume // 监听音量变化
    this.getCameraId = isFunction(options.getCameraId) ? options.getCameraId : () => {
      return ''
    }
    this.getMicrophoneId = isFunction(options.getMicrophoneId) ? options.getMicrophoneId : () => {
      return ''
    }

    this.privateMapKey_ = options.privateMapKey

    this.isJoined_ = false
    this.isPublished_ = false
    this.isAudioMuted = false
    this.isVideoMuted = false
    this.localStream_ = null
    this.remoteStreams_ = []
    this.members_ = new Map()
    this.getAudioLevelTimer_ = -1

    // create a client for RtcClient
    this.client_ = TRTC.createClient({
      mode: this.mode,
      sdkAppId: this.sdkAppId_,
      userId: this.userId_,
      userSig: this.userSig_
    })
    this.handleEvents()
  }

  async join() {
    const that = this
    if (this.isJoined_) {
      console.warn('duplicate RtcClient.join() observed')
      return
    }
    try {
      // join the room
      await this.client_.join({
        roomId: parseInt(this.roomId_)
      })
      this.isJoined_ = true

      // create a local stream with audio/video from microphone/camera
      if (this.getCameraId() && this.getMicrophoneId()) {
        this.localStream_ = TRTC.createStream({
          audio: true,
          video: true,
          userId: this.userId_,
          cameraId: this.getCameraId(),
          microphoneId: this.getMicrophoneId(),
          mirror: true
        })
      } else {
        // not to specify cameraId/microphoneId to avoid OverConstrainedError
        this.localStream_ = TRTC.createStream({
          audio: true,
          video: true,
          userId: this.userId_,
          mirror: true
        })
      }
      if (isFunction(that.addVideo)) {
        that.addVideo({
          id: that.localStream_.getId(),
          userId: that.userId_,
          stream: that.localStream_
        })
      }
      try {
        // initialize the local stream and the stream will be populated with audio/video
        await this.localStream_.initialize()

        this.localStream_.on('player-state-changed', event => {
          if (isFunction(that.loaded)) {
            that.loaded({
              userId: that.userId_,
              stream: that.localStream_,
              streamId: that.localStream_.getId(),
              event: event
            })
          }
        })
      } catch (error) {
        switch (error.name) {
          case 'NotReadableError':
            this.vue.$alert('暂时无法访问摄像头/麦克风，请确保系统允许当前浏览器访问摄像头/麦克风，并且没有其他应用占用摄像头/麦克风', this.title)
            return
          case 'NotAllowedError':
            if (error.message === 'Permission denied by system') {
              this.vue.$alert('请确保系统允许当前浏览器访问摄像头/麦克风', this.title)
            }
            return
          case 'NotFoundError':
            this.vue.$alert('浏览器获取不到摄像头/麦克风设备，请检查设备连接并且确保系统允许当前浏览器访问摄像头/麦克风', this.title)
            return
          default:
            return
        }
      }

      try {
        // publish the local stream
        await this.publish()
        this.vue.$nextTick(() => {
          that.localStream_.play(this.prefix + this.userId_, { objectFit: 'contain' }).then(() => {
            that.localStream_.videoPlayer_.element_.controls = that.controls
          })
        })
      } catch (error) {
        console.error('failed to publish local stream - ', error)
      }
      // 开始获取音量
      this.startGetAudioLevel()
    } catch (error) {
      console.error('join room failed! ' + error)
    }
    // 更新成员状态
    const states = this.client_.getRemoteMutedState()
    for (const state of states) {
      if (state.audioMuted && isFunction(this.changeAudioStatus)) {
        this.changeAudioStatus({
          userId: state.userId,
          status: state.audioMuted
        })
      }
      if (state.videoMuted && isFunction(this.changeVideoStatus)) {
        if (isFunction(this.changeVideoStatus)) {
          this.changeVideoStatus({
            userId: state.userId,
            status: state.audioMuted,
            streamId: this.members_.get(state.userId).getId()
          })
        }
      }
    }
  }

  async leave() {
    if (!this.isJoined_) {
      console.warn('leave() - please join() firstly')
      return
    }
    // ensure the local stream is unpublished before leaving.
    await this.unpublish()

    // leave the room
    await this.client_.leave()

    this.localStream_.stop()
    this.localStream_.close()
    this.localStream_ = null
    this.isJoined_ = false
    // 停止获取音量
    this.stopGetAudioLevel()
    if (isFunction(this.reset)) {
      this.reset()
    }
  }

  async publish() {
    if (!this.isJoined_) {
      console.warn('publish() - please join() firstly')
      return
    }
    if (this.isPublished_) {
      console.warn('duplicate RtcClient.publish() observed')
      return
    }
    try {
      await this.client_.publish(this.localStream_)
    } catch (error) {
      console.error('failed to publish local stream ' + error)
      this.isPublished_ = false
    }

    this.isPublished_ = true
  }

  async unpublish() {
    if (!this.isJoined_) {
      console.warn('unpublish() - please join() firstly')
      return
    }
    if (!this.isPublished_) {
      console.warn('RtcClient.unpublish() called but not published yet')
      return
    }

    await this.client_.unpublish(this.localStream_)
    this.isPublished_ = false
  }

  muteLocalAudio() {
    this.localStream_.muteAudio()
  }

  unmuteLocalAudio() {
    this.localStream_.unmuteAudio()
  }

  muteLocalVideo() {
    this.localStream_.muteVideo()
  }

  unmuteLocalVideo() {
    this.localStream_.unmuteVideo()
  }

  resumeStreams() {
    this.localStream_.resume()
    for (const stream of this.remoteStreams_) {
      stream.resume()
    }
  }

  handleEvents() {
    const that = this
    this.client_.on('error', err => {
      console.error(err)
      that.vue.$alert(err, that.title)
      location.reload()
    })
    this.client_.on('client-banned', err => {
      console.error('client has been banned for ' + err)
      if (!isHidden()) {
        that.vue.$alert('您已被踢出房间', that.title)
        location.reload()
      } else {
        document.addEventListener(
          'visibilitychange',
          () => {
            if (!isHidden()) {
              that.vue.$alert('您已被踢出房间', that.title)
              location.reload()
            }
          },
          false
        )
      }
    })
    // fired when a remote peer is joining the room
    this.client_.on('peer-join', evt => {
      const userId = evt.userId
      if (userId !== that.userId_ && isFunction(that.addMember)) {
        that.addMember(userId)
      }
    })
    // fired when a remote peer is leaving the room
    this.client_.on('peer-leave', evt => {
      const userId = evt.userId
      if (isFunction(that.removeMember)) {
        that.removeMember(userId)
      }
    })
    // fired when a remote stream is added
    this.client_.on('stream-added', evt => {
      const remoteStream = evt.stream
      const userId = remoteStream.getUserId()
      that.members_.set(userId, remoteStream)
      if (remoteStream.getUserId() === that.userId_) {
        // don't need screen shared by us
        that.client_.unsubscribe(remoteStream)
      } else {
        that.client_.subscribe(remoteStream)
      }
    })
    // fired when a remote stream has been subscribed
    this.client_.on('stream-subscribed', evt => {
      const remoteStream = evt.stream
      const uid = remoteStream.getUserId()
      const id = remoteStream.getId()
      that.remoteStreams_.push(remoteStream)
      remoteStream.on('player-state-changed', event => {
        if (isFunction(that.loaded)) {
          that.loaded({
            userId: uid,
            stream: remoteStream,
            streamId: id,
            event: event
          })
        }
      })
      if (isFunction(that.addVideo)) {
        that.addVideo({
          id: id,
          userId: uid,
          stream: remoteStream
        })
      }
      that.vue.$nextTick(() => {
        if (remoteStream.userId_ && remoteStream.userId_.indexOf('share_') > -1) {
          remoteStream.play(that.prefix + uid, { objectFit: 'contain' }).then(() => {
            // Firefox，当video的controls设置为true的时候，video-box无法监听到click事件
            // if (getBrowser().browser === 'Firefox') {
            //   return;
            // }
            remoteStream.videoPlayer_.element_.controls = that.controls
          })
        } else {
          remoteStream.play(that.prefix + uid, { objectFit: 'contain' })
        }
      })
      // 添加“摄像头未打开”遮罩
      // const mask = $('#mask_main').clone()
      // mask.attr('id', 'mask_' + id)
      // mask.appendTo($('#player_' + id))
      // mask.hide()
      if ((!remoteStream.hasVideo()) && isFunction(that.changeVideoStatus)) {
        that.changeVideoStatus({
          userId: remoteStream.getUserId(),
          status: true,
          streamId: id
        })
      }
    })
    // fired when the remote stream is removed, e.g. the remote user called Client.unpublish()
    this.client_.on('stream-removed', evt => {
      const remoteStream = evt.stream
      const id = remoteStream.getId()
      const uid = remoteStream.getUserId()
      const remoteStreamslength = that.remoteStreams_.length
      remoteStream.stop()
      that.remoteStreams_ = that.remoteStreams_.filter(stream => {
        return stream.getId() !== id
      })
      if (isFunction(that.removeMember)) {
        that.removeMember(uid)
      }
      if (isFunction(that.changeAudioStatus)) {
        that.changeAudioStatus({
          userId: uid,
          status: true
        })
      }
      if (isFunction(that.changeVideoStatus)) {
        that.changeVideoStatus({
          userId: uid,
          status: true,
          streamId: id
        })
      }
      if (remoteStreamslength > 0 && that.remoteStreams_.length === 0) {
        that.vue.$alert('其他人已挂断，结束通话', that.title, {
          confirmButtonText: '确定挂断',
          callback: action => {
            that.vue.$nextTick(async() => {
              that.leave()
            })
          }
        })
      }
    })

    this.client_.on('stream-updated', evt => {
      const remoteStream = evt.stream
      const id = remoteStream.getId()
      const uid = remoteStream.getUserId()
      if (isFunction(that.changeVideoStatus)) {
        that.changeVideoStatus({
          userId: uid,
          status: remoteStream.hasVideo(),
          streamId: id
        })
      }
      if (isFunction(that.changeAudioStatus)) {
        that.changeAudioStatus({
          userId: uid,
          status: remoteStream.hasAudio(),
          streamId: id
        })
      }
    })
  }

  showStreamState(stream) {
    // console.log('has audio: ' + stream.hasAudio() + ' has video: ' + stream.hasVideo())
  }

  getUidByStreamId(streamId) {
    for (const [uid, stream] of this.members_) {
      if (stream.getId() === streamId) {
        return uid
      }
    }
  }

  startGetAudioLevel() {
    // 监听音量回调事件，更新每个用户的音量图标
    const that = this
    this.client_.on('audio-volume', ({ result }) => {
      result.forEach(({ userId, audioVolume }) => {
        if (isFunction(that.changeAudioVolume)) {
          that.changeAudioVolume({
            userId: userId,
            audioVolume: audioVolume
          })
        }
      })
    })
    this.client_.enableAudioVolumeEvaluation(100)
  }

  // 停止获取流音量
  stopGetAudioLevel() {
    this.client_.enableAudioVolumeEvaluation(-1)
  }
}

export function createTrtcClient(options) {
  return new RtcClient(options)
}
