<template name="ScrollDialog">
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :title="title"
    :width="width"
    :fullscreen="fullscreen"
    :top="top"
    :modal="modal"
    :modal-append-to-body="modalAppendToBody"
    :append-to-body="appendToBody"
    :lock-scroll="lockScroll"
    :custom-class="customClass"
    :close-on-click-modal="closeOnClickModal"
    :close-on-press-escape="closeOnPressEscape"
    :show-close="showClose"
    :before-close="beforeClose"
    :center="center"
    :destroy-on-close="destroyOnClose"
    @open="open"
    @opened="opened"
    @close="close"
    @closed="closed"
  >
    <div class="dialog-body-container" :style="bodyStyle">
      <el-scrollbar wrap-class="dialog-body-scrollbar">
        <slot />
      </el-scrollbar>
    </div>
    <template slot="title">
      <slot name="title" />
    </template>
    <template slot="footer">
      <slot name="footer" />
    </template>
  </el-dialog>
</template>
<script>
import '@/utils/dialog'
export default {
  name: 'WzDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: undefined
    },
    width: {
      type: String,
      default: '40vw'
    },
    height: {
      type: String,
      default: '50vh'
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    top: {
      type: String,
      default: '15vh'
    },
    modal: {
      type: Boolean,
      default: true
    },
    modalAppendToBody: {
      type: Boolean,
      default: true
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    lockScroll: {
      type: Boolean,
      default: true
    },
    customClass: {
      type: String,
      default: undefined
    },
    closeOnClickModal: {
      type: Boolean,
      default: false
    },
    closeOnPressEscape: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    beforeClose: {
      type: Function,
      default: undefined
    },
    center: {
      type: Boolean,
      default: false
    },
    destroyOnClose: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      bodyStyle: {}
    }
  },
  watch: {
    height(val) {
      this.setStyle()
    },
    visible(val) {
      this.dialogVisible = val
    }
  },
  mounted() {
    this.setStyle()
    this.dialogVisible = this.visible
  },
  methods: {
    setStyle() {
      if (this.height !== undefined && this.height !== null) {
        this.bodyStyle = { height: this.height }
      }
    },
    open() {
      this.$emit('open')
      this.$emit('update:visible', this.dialogVisible)
    },
    opened() {
      this.$emit('opened')
      this.$emit('update:visible', this.dialogVisible)
    },
    close() {
      this.$emit('close')
      this.$emit('update:visible', this.dialogVisible)
    },
    closed() {
      this.$emit('closed')
      this.$emit('update:visible', this.dialogVisible)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
::v-deep .el-dialog {
  background: url("../../assets/image/body-bg.jpg") no-repeat center top;
  box-shadow: 0px 0px 15px rgba(0, 255, 255, 0.5) inset;
  border: 1px solid #00ffff;
  .el-dialog__header {
    border-bottom: 1px solid #00ffff;
    box-sizing: border-box;
    height: 53px;
    padding: 15px;
    margin: 0;
    .el-dialog__title {
      color: #ffffff;
      font-size: 16px;
      line-height: 1.42857143;
    }
    .el-dialog__headerbtn {
      i {
        color: #ffffff;
      }
      i:hover {
        color: #00ffff;
      }
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #00ffff;
    box-sizing: border-box;
    text-align: center;
    position: relative;

    .el-button {
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;;
      min-width: 90px;
      min-height: 36px;
      text-align: center;
      margin: 0 auto;
      padding: 0 8px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      i {
        font-size: 30px;
        padding: 0;
        margin: 3px auto 0;
        display: block;
        color:#ffffff
      }
      span {
        height: 29px;
        display: block;
        line-height: 2.4;
      }
    }

    .el-button--default {
      border-color:#00ffff;
      box-shadow: 0px 0px 15px rgba(0, 255, 255, 0.5) inset;
      background: rgba(0, 255, 255, 0.1);
      background-image: linear-gradient(
        rgba(0, 255, 255, 0.2) 1%,
        rgba(0, 255, 255, 0.05) 60%,
        rgba(0, 255, 255, 0) 60%
      );
    }
    .el-button--default:hover {
      color: #00ffff;
    }

    .el-button--danger {
      border-color:#ff0000;
      box-shadow: 0px 0px 15px rgba(255, 0, 0, 0.5) inset;
      background: rgba(255, 0, 0, 0.1);
      background-image: linear-gradient(
        rgba(255, 0, 0, 0.4) 1%,
        rgba(255, 0, 0, 0.2) 60%,
        rgba(255, 0, 0, 0) 60%
      );
    }
    .el-button--danger:hover {
      color: #ff0000;
    }
    .el-button img {
      display: block;
      margin: 0px auto;
      width: 30px;
      height: 30px;
    }
  }
}
::v-deep .dialog-body-container {
  padding: 0px !important;
  margin: 0 !important;
  width: 100%;

  .dialog-body-scrollbar {
    overflow-x: hidden !important;

    .horizontal-collapse-transition {
      transition: 0s width ease-in-out, 0s padding-left ease-in-out,
        0s padding-right ease-in-out;
    }

    .el-scrollbar__view {
      margin: 0;
      padding: 5px 0 0 0 !important;
      height: calc(100% - 5px);
    }
  }

  .el-scrollbar__bar.is-vertical {
    right: 0px;
  }

  .el-scrollbar__bar.is-horizontal {
    display: none;
  }

  .el-scrollbar {
    width: 100%;
    height: 100%;
    margin: 0;

    .el-scrollbar__wrap {
      overflow-x: hidden !important;
      overflow-y: auto !important;
    }
  }
}
</style>
