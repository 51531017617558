<template>
  <div class="login">
    <div class="main">
      <div class="bg-sign-img">
        <el-image
          :src="require('../../assets/image/bg-sign.png')"
          :fit="'cover'"
        />
      </div>
      <div v-if="!loginBoxShow" class="bg-down-img">
        <el-image
          :src="require('../../assets/image/down.png')"
          :fit="'cover'"
          @click="onDown"
        />
      </div>
      <div v-if="!loginBoxShow" class="bg-down-animation" @click="onDown">
        <next-animation />
      </div>
      <div class="footer-text">技术支持: 物知云</div>

      <!-- 登录输入框 -->
      <template v-if="loginBoxShow">
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          class="login-input animated bounce bounceInDown faster"
          auto-complete="on"
          label-position="left"
        >
          <div class="input-box">
            <el-input
              v-model="loginForm.name"
              size="medium"
              prefix-icon="el-icon-user"
              placeholder="请输入账号"
            />
          </div>
          <div class="input-box right-input">
            <el-input
              v-model="loginForm.password"
              show-password
              size="medium"
              prefix-icon="el-icon-lock"
              placeholder="请输入密码"
            />
          </div>
          <div class="login-btn">
            <el-button
              size="medium"
              type="primary"
              class="el-button"
              :loading="loading"
              @click="handleLogin"
            >登录</el-button>
          </div>
          <div class="login-checked">
            <input v-model="checked" type="checkbox">
            <span class="login-remember-pass">记住密码</span>
          </div>
        </el-form>
      </template>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import nextAnimation from '@/components/next-animation'
import { validUsername } from '@/utils/validate'

export default {
  name: 'Login',
  components: { nextAnimation },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!validUsername(value)) {
        callback(new Error('请输入正确企业管理员账号'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('账号密码长度不能低于6位'))
      } else {
        callback()
      }
    }
    return {
      loginBoxShow: false,
      checked: false,
      loading: false,
      loginForm: {
        name: '',
        password: ''
      },
      loginRules: {
        name: [
          { required: true, trigger: 'blur', validator: validateUsername }
        ],
        password: [
          { required: true, trigger: 'blur', validator: validatePassword }
        ]
      },
      redirect: undefined,
      otherQuery: {}
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        // console.log("route", route);
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  created() {
    const user = JSON.parse(window.localStorage.getItem('User_key'))
    if (!user) return
    this.checked = true
    this.loginForm.name = user.name
    this.loginForm.password = user.password
  },
  methods: {
    // 显示登陆输入框
    onDown() {
      this.loginBoxShow = true
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          // 登录前清空缓存的projectId
          this.$store.dispatch('user/clearProjectId', '').then(() => {
            this.loading = true
            this.$store
              .dispatch('user/login', this.loginForm)
              .then(() => {
                // //判断是否记住密码
                if (this.checked) {
                  window.localStorage.setItem(
                    'User_key',
                    JSON.stringify(this.loginForm)
                  )
                } else {
                  window.localStorage.removeItem('User_key')
                }
                this.$router.push({
                  path: this.redirect || '/home',
                  query: this.otherQuery
                })
                this.loading = false
                screenfull.toggle()
              })
              .catch(() => {
                this.loading = false
              })
          })
        } else {
          console.log('错误提交！！')
          return false
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/image/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  .main {
    display: flex;
    justify-content: center;
    .bg-down-img {
      width: 80px;
      height: 80px;
      position: absolute;
      top: 550px;
      cursor: pointer;
    }
    .bg-down-animation {
      position: absolute;
      top: 605px;
      cursor: pointer;
    }
    .bg-sign-img {
      width: 486px;
      height: 125px;
      position: absolute;
      top: 373px;
    }
    .footer-text {
      letter-spacing: 1px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #eaeaea;
      position: absolute;
      bottom: 34px;
    }
    .login-input {
      position: absolute;
      top: 510px;
      display: flex;
      align-items: center;
      .input-box {
        width: 280px;
      }
      .right-input {
        margin-left: 5px;
      }
      .login-btn {
        margin-left: 5px;
      }
      .login-btn .el-button {
        width: 80px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 2px;
      }
      .login-checked {
        height: 36px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        .login-remember-pass {
          color: #ffffff;
          letter-spacing: 1px;
          font-size: 14px;
          margin-left: 10px;
        }
        input[type="checkbox"] {
          display: flex;
          justify-self: center;
          align-self: center;
          vertical-align: middle;
          border: none;
          appearance: none; /*关键代码*/
          -webkit-appearance: none;
          outline: none;
          cursor: pointer;
          width: 17px;
          height: 17px;
        }
        input[type="checkbox"]::after {
          content: " ";
          display: inline-block;
          width: 17px;
          height: 17px;
          border: solid 1px #fff;
          color: #fff;
          background-color: #fff;
          border-radius: 2px;
        }
        input[type="checkbox"]:checked:after {
          display: flex;
          justify-content: center;
          align-items: center;
          content: " √ ";
          width: 17px;
          height: 17px;
          border: solid 1px #1182e0;
          color: #fff;
          background: #1182e0;
          font-family: layui-icon !important;
          font-size: 13px;
          font-style: normal;
          font-weight: bold;
          -webkit-font-smoothing: antialiased;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
