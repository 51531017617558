import {
  getProjectHome, getTodayCount, getSevenDaysCount, getDeviceStatus, getAllTower, getAllLifter, getAllEnvSensor, getPastCarCount,
  getLatest, getLatestVehicle, getLatestAlarmLog, getRateStat, getTrendStatLine, getArticleList, getPrjComplexStat,
  getProject
} from '@/api/project'
import { getVehicleLineStat } from '@/api/vehicle'
import { getWeather } from '@/api/weather'
import { parseTime, getWeek, hasVal } from '@/utils/index'
const state = {
  todayCount: {}, // 今日进场管理员和劳务人员数量
  sevenDaysCount: {}, // 近七天劳务情况数据
  towerDeviceStatus: undefined, // 塔吊设备状态数量统计数据
  towerAllData: undefined, // 塔吊实时数据
  lifterData: undefined, // 人货梯设备状态数量统计数据
  lifterAllData: undefined, // 人货梯实时数据
  envAllData: undefined, // 环境监测实时数据
  pastCarCount: {}, // 车辆通行数据
  sevenDaysCarCount: undefined, // 近七天车辆通行数据
  latestPeople: {}, // 最新一条人员信息
  latestVehicles: {}, // 最新一条车辆信息
  latestAlarm: {}, // 最新一条报警信息
  qiteData: {}, // 质量统计率
  sefaData: {}, // 安全统计率
  qiteLineData: undefined, // 质量趋势图数据
  sefaLineData: undefined, // 安全趋势图数据
  articleList: [], // 政策资讯数据
  cTData: {}, // 主页-中-上-展示数据
  projectInfo: {}, // 项目关联信息
  weather: {}// 天气数据
}
const mutations = {
  SET_TODAYCOUNT(state, todayCount) {
    todayCount.worker = String(todayCount.worker)
    todayCount.manager = String(todayCount.manager)
    state.todayCount = { ...todayCount }
  },
  SET_SEVENDAYSCOUNT(state, sevenDaysCount) {
    state.sevenDaysCount = sevenDaysCount
  },
  SET_DEVICESTATUS(state, towerDeviceStatus) {
    state.towerDeviceStatus = towerDeviceStatus
  },
  SET_TOWERALL(state, towerAllData) {
    state.towerAllData = towerAllData
  },
  SET_LIFTERSTATUS(state, lifterData) {
    state.lifterData = lifterData
  },
  SET_lIFTERAll(state, lifterAllData) {
    const newArr = []
    lifterAllData.forEach((j) => {
      j.lifterBaskets.forEach(d => {
        newArr.push({
          name: j.name,
          ...d
        })
      })
    })
    state.lifterAllData = newArr
  },
  SET_ENVALL(state, envAllData) {
    state.envAllData = envAllData
  },
  SET_CARCOUNT(state, pastCarCount) {
    pastCarCount.entered = String(pastCarCount.entered)
    pastCarCount.exited = String(pastCarCount.exited)
    pastCarCount.staying = String(pastCarCount.staying)
    pastCarCount.totalStaying = String(pastCarCount.totalStaying)
    state.pastCarCount = { ...pastCarCount }
  },
  SET_SEVENDAYSCARS(state, sevenDaysCarCount) {
    state.sevenDaysCarCount = sevenDaysCarCount
  },
  SET_LATESTPEOPLE(state, latestPeople) {
    state.latestPeople = latestPeople
  },
  SET_LATESTVEHICLES(state, latestVehicles) {
    state.latestVehicles = latestVehicles
  },
  SET_LATESTALARM(state, latestAlarm) {
    state.latestAlarm = latestAlarm
  },
  SET_QITEDATA(state, qiteData) {
    qiteData.checkedRate = Math.round((qiteData.checkedRate * 100))
    state.qiteData = qiteData
  },
  SET_SEFADATA(state, sefaData) {
    sefaData.checkedRate = Math.round((sefaData.checkedRate * 100))
    state.sefaData = sefaData
  },
  SET_QITELINEDATA(state, qiteLineData) {
    state.qiteLineData = qiteLineData
  },
  SET_SEFALINEDATA(state, sefaLineData) {
    state.sefaLineData = sefaLineData
  },
  SET_ARTICLELIST(state, articleList) {
    state.articleList = articleList
  },
  SET_CTDATA(state, cTData) {
    state.cTData = cTData
  },
  SET_PROJECINFO(state, projectInfo) {
    state.projectInfo = projectInfo
    // console.log('形象,航拍图，云监工', state.projectInfo)
  },
  SET_WEATHER(state, weather) {
    weather.dateNow = parseTime(new Date().getTime(), '{y}-{m}-{d}')
    weather.week = getWeek(weather.dateNow)
    state.weather = weather
    // console.log('天气数据', state.weather)
  }
}

const actions = {
  /**
     * 获取今日进场管理员和劳务人员数量
     */
  getHome({ commit }) {
    return new Promise((resolve, reject) => {
      getProjectHome().then(res => {
        const { data } = res
        if (hasVal(data)) {
          if (hasVal(data.todayCount)) {
            commit('SET_TODAYCOUNT', data.todayCount)
          }
          if (hasVal(data.sevenDaysCount)) {
            commit('SET_SEVENDAYSCOUNT', data.sevenDaysCount)
          }
          if (hasVal(data.towerStatus)) {
            commit('SET_DEVICESTATUS', data.towerStatus)
          }
          if (hasVal(data.lifterStatus)) {
            commit('SET_LIFTERSTATUS', data.lifterStatus)
          }
          if (hasVal(data.towerAllData)) {
            commit('SET_TOWERALL', data.towerAllData)
          }
          if (hasVal(data.lifterAllData)) {
            commit('SET_lIFTERAll', data.lifterAllData)
          }
          if (hasVal(data.envAllData)) {
            commit('SET_ENVALL', data.envAllData)
          }
          if (hasVal(data.pastCarCount)) {
            commit('SET_CARCOUNT', data.pastCarCount)
          }
          if (hasVal(data.sevenDaysCarCount)) {
            commit('SET_SEVENDAYSCARS', data.sevenDaysCarCount)
          }
          if (hasVal(data.latestPeople)) {
            commit('SET_LATESTPEOPLE', data.latestPeople)
          }
          if (hasVal(data.latestVehicles)) {
            commit('SET_LATESTVEHICLES', data.latestVehicles)
          }
          if (hasVal(data.latestAlarm)) {
            commit('SET_LATESTALARM', data.latestAlarm)
          }
          if (hasVal(data.qiteData)) {
            commit('SET_QITEDATA', data.qiteData)
          }
          if (hasVal(data.safeData)) {
            commit('SET_SEFADATA', data.safeData)
          }
          if (hasVal(data.qiteLineData)) {
            commit('SET_QITELINEDATA', data.qiteLineData)
          }
          if (hasVal(data.safeLineData)) {
            commit('SET_SEFALINEDATA', data.safeLineData)
          }
          if (hasVal(data.articleList)) {
            commit('SET_ARTICLELIST', data.articleList)
          }
          if (hasVal(data.centerTopData)) {
            commit('SET_CTDATA', data.centerTopData)
          }
          if (hasVal(data.projectInfo)) {
            commit('SET_PROJECINFO', data.projectInfo)
          }
        }
        resolve()
      })
    })
  },
  /**
     * 获取今日进场管理员和劳务人员数量
     */
  getTodayCountDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getTodayCount().then(res => {
        const { data } = res
        commit('SET_TODAYCOUNT', data)
        resolve()
      })
    })
  },
  /**
     * 获取近七天劳务情况数据
     */
  getSevenDaysCountDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getSevenDaysCount().then(res => {
        const { data } = res
        commit('SET_SEVENDAYSCOUNT', data)
        resolve()
      })
    })
  },
  /**
     * 获取设备状态数量统计数据
     * @param {String} type 设备类型
     */
  getDeviceStatusDatas({ commit }, type) {
    return new Promise((resolve, reject) => {
      getDeviceStatus({ deviceType: type }).then(res => {
        const { data } = res
        switch (type) {
          case 'TOWE':// 塔吊
            commit('SET_DEVICESTATUS', data)
            break
          case 'LIFTER':// 人货梯
            commit('SET_LIFTERSTATUS', data)
            break
        }
        resolve()
      })
    })
  },
  /**
     * 获取塔吊实时数据
     */
  getAllTowerDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getAllTower().then(res => {
        const { data } = res
        commit('SET_TOWERALL', data.items)
        resolve()
      })
    })
  },
  /**
     * 获取人货梯实时数据
     */
  getAllLifterDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getAllLifter().then(res => {
        const { data } = res
        commit('SET_lIFTERAll', data.items)
        resolve()
      })
    })
  },
  /**
     * 获取人货梯实时数据
     */
  getAllEnvSensorDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getAllEnvSensor().then(res => {
        const { data } = res
        commit('SET_ENVALL', data.items)
        resolve()
      })
    })
  },
  /**
     * 获取车辆通行数据
     */
  getPastCarCountDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getPastCarCount().then(res => {
        const { data } = res
        commit('SET_CARCOUNT', data)
        resolve()
      })
    })
  },
  /**
     * 近7天车辆通行数据
     */
  getVehicleLineStatDatas({ commit }, top) {
    return new Promise((resolve, reject) => {
      getVehicleLineStat(top).then(res => {
        const { data } = res
        commit('SET_SEVENDAYSCARS', data.items)
        resolve()
      })
    })
  },
  /**
     * 获取最新一条人员数据
     */
  getLatestDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getLatest().then(res => {
        const { data } = res
        if (data === null) return resolve()
        commit('SET_LATESTPEOPLE', data)
        resolve()
      })
    })
  },
  /**
     * 获取最新一条车辆数据
     */
  getLatestVehicleDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getLatestVehicle().then(res => {
        const { data } = res
        if (data === null) return resolve()
        commit('SET_LATESTVEHICLES', data)
        resolve()
      })
    })
  },
  /**
     * 获取最新一条报警数据
     */
  getLatestAlarmLogDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getLatestAlarmLog().then(res => {
        const { data } = res
        if (data === null) return resolve()
        commit('SET_LATESTALARM', data)
        resolve()
      })
    })
  },
  /**
     * 获取质量和安全数据统计率
     * @param {String} type 设备类型
     */
  getRateStatDatas({ commit }, type) {
    return new Promise((resolve, reject) => {
      getRateStat({ Type: type }).then(res => {
        const { data } = res
        switch (type) {
          case 'QUALITY':// 质量
            commit('SET_QITEDATA', data)
            break
          case 'SAFETY':// 安全
            commit('SET_SEFADATA', data)
            break
        }
        resolve()
      })
    })
  },
  /**
     * 获取质量和安全趋势图数据
     * @param {String} type 设备类型
     */
  getTrendStatLineDatas({ commit }, type) {
    return new Promise((resolve, reject) => {
      getTrendStatLine({ Type: type }).then(res => {
        const { data } = res
        switch (type) {
          case 'QUALITY':// 质量
            commit('SET_QITELINEDATA', data)
            break
          case 'SAFETY':// 安全
            commit('SET_SEFALINEDATA', data)
            break
        }
        resolve()
      })
    })
  },
  /**
     * 获取政策资讯
     */
  getArticleListDatas({ commit }, curr) {
    return new Promise((resolve, reject) => {
      getArticleList({ Limit: curr }).then(res => {
        const { data } = res
        commit('SET_ARTICLELIST', data.items)
        resolve()
      })
    })
  },
  /**
     * 获取主页-中-上展示数据
     */
  getPrjComplexStatDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getPrjComplexStat().then(res => {
        const { data } = res
        commit('SET_CTDATA', data)
        resolve()
      })
    })
  },
  /**
     * 获取项目关联信息
     */
  getProjectDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getProject().then(res => {
        const { data } = res
        commit('SET_PROJECINFO', data)
        resolve()
      })
    })
  },
  /**
     * 获取天气数据
     */
  getWeatherDatas({ commit }) {
    return new Promise((resolve, reject) => {
      getWeather().then(res => {
        const { result } = res
        if (hasVal(result) && hasVal(result.HeWeather5)) {
          commit('SET_WEATHER', result.HeWeather5[0])
        }
        resolve()
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
