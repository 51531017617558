import request from '@/utils/request'

/** *
 * 获取指定项目下的所有在场人员信息
 */
export function getApproachList() {
  return request({
    url: '/Personnel/GetApproachList',
    method: 'GET'
  })
}

/** *
 * 获取施工人员管理图标统计信息
 */
export function getPrjPerWorkerRate() {
  return request({
    url: '/Personnel/GetPrjPerWorkerRate',
    method: 'GET'
  })
}

/** *
 * 获取人员管理统计信息（中）
 */
export function getPrjAttendStats() {
  return request({
    url: '/Personnel/GetPrjAttendStats',
    method: 'GET'
  })
}

/** *
* 获取施工人员管理统计图统计信息
*/
export function getPerWorkerChartStats() {
  return request({
    url: '/Personnel/GetPerWorkerChartStats',
    method: 'GET'
  })
}

/** *
* 获取施工人员管理统计信息（下）
*/
export function getPrjWorkerStats() {
  return request({
    url: '/Personnel/GetPrjWorkerStats',
    method: 'GET'
  })
}

/** *
* 获取人员关键指标数据信息
*/
export function getLaborKeyPoint(type) {
  return request({
    url: '/Personnel/GetLaborKeyPoint',
    method: 'GET',
    params: {
      personnelType: type
    }
  })
}

/** *
* 获取劳动力趋势数据
*/
export function getLaborForceTrendStat() {
  return request({
    url: '/Personnel/GetLaborForceTrendStat',
    method: 'GET'
  })
}

/** *
* 获取出勤人数趋势数据
*/
export function getAttendanceTrendStat() {
  return request({
    url: '/Personnel/GetAttendanceTrendStat',
    method: 'GET'
  })
}

/** *
* 获取各工种平均工时
*/
export function getWorkTypeAverageWorkingTime() {
  return request({
    url: '/Personnel/GetWorkTypeAverageWorkingTime',
    method: 'GET'
  })
}

/** *
* 获取在场工人和管理员考勤列表
*/
export function getAttendanceLatestList(top, type) {
  return request({
    url: '/Personnel/GetAttendanceLatestList',
    method: 'GET',
    params: {
      limit: top,
      personnelType: type
    }
  })
}

/** *
* 获取红黑榜人员
*/
export function getPersonnelRank() {
  return request({
    url: '/Personnel/GetPersonnelRank',
    method: 'GET'
  })
}

export function call(data) {
  return request({
    url: '/Call/Call',
    method: 'POST',
    data
  })
}
