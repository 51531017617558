import request from '@/utils/request'

export function getOwnerHome(params) {
  return request({
    url: '/Owners/Get',
    method: 'get',
    params
  })
}

export function getOwnerInfo(params) {
  return request({
    url: '/Owners/GetOwnerInfo',
    method: 'get',
    params
  })
}

export function getOwnerAlarmLog(params) {
  return request({
    url: '/Owners/GetOwnerAlarmLog',
    method: 'get',
    params
  })
}

export function getOwnerAttendRate(params) {
  return request({
    url: '/Personnel/GetOwnerAttendRate',
    method: 'get',
    params
  })
}

export function getWorkers(params) {
  return request({
    url: '/Personnel/GetOwnerWorkerAttendRate',
    method: 'get',
    params
  })
}

export function getGroupDevice(params) {
  return request({
    url: '/Device/GetOwnerDeviceStats',
    method: 'get',
    params
  })
}

export function getGrpupSalaryInfo(params) {
  return request({
    url: '/Salary/GetOwnerSalaryStats',
    method: 'get',
    params
  })
}

export function getPrjSalaryStats(params) {
  return request({
    url: '/Salary/GetPrjSalaryStats',
    method: 'get',
    params
  })
}

export function getPrjMaps(params) {
  return request({
    url: '/Project/GetPrjMaps',
    method: 'get',
    params
  })
}
