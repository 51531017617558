const getters = {
  token: state => state.user.token,
  name: state => state.user.name,
  projectId: state => state.user.projectId,
  topDialogvisible: state => state.user.topDialogvisible,
  callStatus: state => state.trtc.callStatus,
  isInviter: state => state.trtc.isInviter,
  isAccepted: state => state.trtc.isAccepted,
  meetingUserIdList: state => state.trtc.meetingUserIdList,
  muteVideoUserIdList: state => state.trtc.muteVideoUserIdList
}
export default getters
