import request from '@/utils/request'

/** *
 * 获取车辆指定时间段内的出入通行统计
 */
export function getVehicleLineStat(top) {
  return request({
    url: '/Device/GetVehicleLineStat',
    method: 'GET',
    params: {
      topDay: top
    }
  })
}

/** *
 * 获取车辆通行列表
 */
export function getVehiclePassList(top) {
  return request({
    url: '/Device/GetVehiclePassList',
    method: 'GET',
    params: {
      top: top
    }
  })
}

/** *
 * 获取车辆出入通行信息
 */
export function getVehicleInOutList(isIn) {
  return request({
    url: '/Device/GetVehicleInOutList',
    method: 'GET',
    params: {
      isIn: isIn
    }
  })
}

/** *
 * 获取通行车辆的颜色统计
 */
export function getVehiclesColorStats() {
  return request({
    url: '/Device/GetVehiclesColorStats',
    method: 'GET'
  })
}

/** *
 * 获取车辆通行统计
 */
export function getPastVehicleCount() {
  return request({
    url: '/Device/GetPastVehicleCount',
    method: 'GET'
  })
}

