import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login/index'

Vue.use(VueRouter)

const constantRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  // 此段路由为根路径不要注释
  {
    path: '/',
    name: 'Home',
    redirect: '/home'
    // component: () => import('../views/Home/index.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home/index.vue')
  },
  // {
  //   path: '/project',
  //   name: 'Project',
  //   component: () => import('../views/project/index.vue')
  // },
  {
    path: '/project',
    name: 'Project',
    component: () => import('../views/project-home/index')
  },
  {
    path: '/monitor',
    name: 'Monitor',
    component: () => import('../views/monitor/index.vue')
  },
  {
    path: '/device/vehicle',
    name: 'Vehicle',
    component: () => import('../views/device/vehicle.vue')
  },
  {
    path: '/personnel', // 人员管理
    name: 'Personnel',
    component: () => import('../views/personnel/index.vue')
  },
  {
    path: '/inspection', // 人工巡检(质量&安全)
    name: 'Inspection',
    component: () => import('../views/inspection/index.vue')
  },
  {
    path: '/vehicle', // 车辆管理
    name: 'Vehicle',
    component: () => import('../views/vehicle/index.vue')
  },
  {
    path: '/envSensors', // 环境监测
    name: 'EnvSensors',
    component: () => import('../views/envSensors/index.vue')
  },
  {
    path: '/lawArticles', // 公告看板
    name: 'LawArticles',
    component: () => import('../views/lawArticles/index.vue')
  },
  {
    path: '/lifter',
    name: 'Lifter',
    component: () => import('../views/lifter/index.vue')
  },
  {
    path: '/tower',
    name: 'Tower',
    component: () => import('../views/tower/index.vue')
  },
  {
    path: '/machine', // 设备管理
    name: 'Machine',
    component: () => import('../views/machine/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: constantRoutes
})

export default router
