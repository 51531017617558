import request from '@/utils/request'

/**
 * 获取项目数据
 */
 export function getProjectHome(params) {
  return request({
    url: '/Project/Get',
    method: 'get',
    params
  })
}

/**
 * 获取今日进场管理员和劳务人员数量
 */
export function getTodayCount(params) {
  return request({
    url: '/Personnel/GetTodayAttendanceCount',
    method: 'get',
    params
  })
}

/**
 * 近七天劳务情况趋势图
 */
export function getSevenDaysCount(params) {
  return request({
    url: '/Personnel/GetAttendanceTrendStat',
    method: 'get',
    params
  })
}

/**
 * 获取设备各状态数量统计
 */
export function getDeviceStatus(params) {
  return request({
    url: '/Device/GetDeviceStatusStat',
    method: 'get',
    params
  })
}

/**
 * 获取所有塔吊设备的实时数据
 */
export function getAllTower(params) {
  return request({
    url: '/Device/GetAllTowerRealData',
    method: 'get',
    params
  })
}

/**
 * 获取所有人货梯设备的实时数据
 */
export function getAllLifter(params) {
  return request({
    url: '/Device/GetAllLifterRealData',
    method: 'get',
    params
  })
}

/**
 * 获取所有环境监测设备的实时数据
 */
export function getAllEnvSensor(params) {
  return request({
    url: '/Device/GetAllEnvSensorRealData',
    method: 'get',
    params
  })
}

/**
 * 获取车辆通行数量
 */
export function getPastCarCount(params) {
  return request({
    url: '/Device/GetPastVehicleCount',
    method: 'get',
    params
  })
}

/**
 * 获取最新一条人员打开信息
 */
export function getLatest(params) {
  return request({
    url: '/Personnel/GetLatestAttendance',
    method: 'get',
    params
  })
}

/**
 * 获取最新一条车辆进出信息
 */
export function getLatestVehicle(params) {
  return request({
    url: '/Device/GetLatestVehicle',
    method: 'get',
    params
  })
}

/**
 * 获取最新一条报警信息
 */
export function getLatestAlarmLog(params) {
  return request({
    url: '/Project/GetPrjLatestAlarmLog',
    method: 'get',
    params
  })
}

/**
 * 获取质量和安全统计率
 */
export function getRateStat(params) {
  return request({
    url: '/PatrolProblem/GetCheckedRateStat',
    method: 'get',
    params
  })
}

/**
 * 获取质量和安全趋势图
 */
export function getTrendStatLine(params) {
  return request({
    url: '/PatrolProblemStats/Project/TrendStatLine',
    method: 'get',
    params
  })
}

/**
 * 获取政策咨询
 */
export function getArticleList(params) {
  return request({
    url: '/LawArticles/GetSimpleArticleList',
    method: 'get',
    params
  })
}

/**
 * 获取主页-中-上展示数据
 */
export function getPrjComplexStat(params) {
  return request({
    url: '/Project/GetPrjComplexStat',
    method: 'get',
    params
  })
}

/**
 * 获取项目关联信息
 */
export function getProject(params) {
  return request({
    url: '/Project/GetProject',
    method: 'get',
    params
  })
}
