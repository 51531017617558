<template>
  <div v-if="isProjectPage">
    <drag-float @click="onClick">
      <div>
        <i class="wz-icon-video-call" />
        <div>{{ title }}</div>
      </div>
    </drag-float>
    <wz-dialog title="项目通讯录" :visible.sync="dialogPersonnelVisible">
      <div class="dialog-body">
        <el-row :gutter="20">
          <el-col v-for="item in checkeds" :key="item.id" :span="4">
            <div class="block">
              <div>
                <span class="avatar-wrapper">
                  <el-image
                    class="avatar"
                    fit="scale-down"
                    :src="item.mugshot"
                    :alt="item.name"
                  >
                    <img
                      slot="error"
                      :src="defaultAvatar"
                      :alt="item.name"
                      class="el-image__inner"
                      style="object-fit: scale-down"
                    >
                  </el-image>
                </span>
                <div class="title">{{ item.name }}</div>
                <div class="team">{{ item.teamName }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="search-box">
          <el-input
            v-model="search.keywords"
            placeholder="请输入组名称、姓名、电话号码查找"
            class="input-with-select"
            size="small"
          >
            <el-select
              slot="prepend"
              v-model="search.teamId"
              placeholder="请班组"
              style="width: 100px"
              @change="onSearch"
            >
              <el-option
                v-for="item in teams"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <el-button slot="append" icon="el-icon-search" @click="onSearch" />
          </el-input>
        </div>
        <el-row :gutter="20">
          <el-checkbox-group
            v-if="checkedMax > 1"
            v-model="checkedIds"
            size="mini"
            :max="checkedMax"
            @change="onChange"
          >
            <el-col v-for="item in currentAddressList" :key="item.id" :span="4">
              <div class="block">
                <el-checkbox :label="item.id" />
                <div @click="onCheckbox(item)">
                  <span class="avatar-wrapper">
                    <el-image
                      class="avatar"
                      fit="scale-down"
                      :src="item.mugshot"
                      :alt="item.name"
                    >
                      <img
                        slot="error"
                        :src="defaultAvatar"
                        :alt="item.name"
                        class="el-image__inner"
                        style="object-fit: scale-down"
                      >
                    </el-image>
                  </span>
                  <div class="title">{{ item.name }}</div>
                  <div class="team">{{ item.teamName }}</div>
                </div>
              </div>
            </el-col>
          </el-checkbox-group>
          <el-radio-group
            v-else
            v-model="checked"
            size="mini"
            @change="onChange"
          >
            <el-col v-for="item in currentAddressList" :key="item.id" :span="4">
              <div class="block">
                <el-radio :label="item.id" />
                <div @click="onCheckbox(item)">
                  <span class="avatar-wrapper">
                    <el-image
                      class="avatar"
                      fit="scale-down"
                      :src="item.mugshot"
                      :alt="item.name"
                    >
                      <img
                        slot="error"
                        :src="defaultAvatar"
                        :alt="item.name"
                        class="el-image__inner"
                        style="object-fit: scale-down"
                      >
                    </el-image>
                  </span>
                  <div class="title">{{ item.name }}</div>
                  <div class="team">{{ item.teamName }}</div>
                </div>
              </div>
            </el-col>
          </el-radio-group>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="left">
          <label>
            <el-select
              v-model="trtcVideo.cameraId"
              size="small"
              placeholder="请选择摄像头"
            >
              <div slot="prefix" class="select-label">摄像头：</div>
              <el-option
                v-for="item in trtcVideo.cameras"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              />
            </el-select>
          </label>
          <label>
            <el-select
              v-model="trtcVideo.micId"
              size="small"
              placeholder="请选择麦克风"
            >
              <div slot="prefix" class="select-label">麦克风：</div>
              <el-option
                v-for="item in trtcVideo.microphones"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              />
            </el-select>
          </label>
        </div>
        <div class="right">
          <el-button @click="onCall"> 发起视频通话 </el-button>
        </div>
      </div>
    </wz-dialog>
    <wz-dialog
      title="视频通话"
      :visible.sync="dialogVideoVisible"
      :before-close="onLeave"
    >
      <el-row :gutter="20" class="el-row-video">
        <el-col v-for="item in trtcVideo.members" :key="item.userId" :span="trtcVideo.span" :style="`height:${trtcVideo.itemHeight}`">
          <div :id="'video_' + item.userId" class="play-box">
            <div class="video-tools">
              <div class="video-name">{{ item.name }}</div>
              <img
                id="video-btn"
                onClick="event.cancelBubble = true"
                :src="item.isCamOn ? imgCameraOn : imgCameraOff"
                @click="onChangeVideoStatus(item)"
              >
              <img
                id="mic-btn"
                onClick="event.cancelBubble = true"
                :src="item.isMicOn ? imgMicOn : imgMicOff"
                @click="onChangeAudioStatus(item)"
              >
            </div>
            <div v-if="!item.loading && !item.isCamOn" class="not-video">
              摄像头未打开
            </div>
            <loading v-if="item.loading" :append="true" />
          </div>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer dialog-video-footer">
        <div class="left">
          <el-button
            type="danger"
            icon="el-icon-circle-close"
            @click="onLeave"
          >结束</el-button>
        </div>
        <div class="right">
          <el-button
            v-if="trtcVideo.currentUser && trtcVideo.currentUser.isMicOn"
            icon="el-icon-turn-off-microphone"
            @click="onChangeAudioStatus(trtcVideo.currentUser)"
          >禁用语音</el-button>
          <el-button
            v-else
            icon="el-icon-microphone"
            @click="onChangeAudioStatus(trtcVideo.currentUser)"
          >开启语音</el-button>
          <el-button
            v-if="trtcVideo.currentUser && trtcVideo.currentUser.isCamOn"
            icon="wz-icon-turn-off-camera"
            @click="onChangeVideoStatus(trtcVideo.currentUser)"
          >禁用视频</el-button>
          <el-button
            v-else
            icon="wz-icon-turn-on-camera"
            @click="onChangeVideoStatus(trtcVideo.currentUser)"
          >开启视频</el-button>
        </div>
      </div>
    </wz-dialog>
  </div>
</template>

<script>
import TRTC from 'trtc-js-sdk'
import { createTrtcClient } from './trtc-client'
// import { createShareClient } from './share-client'
import { rtcDetection } from './trtc-detection'
import { getApproachList, call } from '@/api/personnel'
import dragFloat from '@/components/dragFloat'
import WzDialog from '@/components/wz-dialog'
import { isFunction, hasVal, inArray } from '@/utils/index'
import Loading from '@/components/loading'
import imgCameraOn from '@/assets/image/video-call/big-camera-on.png'
import imgCameraOff from '@/assets/image/video-call/big-camera-off.png'
import imgMicOn from '@/assets/image/video-call/big-mic-on.png'
import imgMicOff from '@/assets/image/video-call/big-mic-off.png'
export default {
  name: 'VideoCall',
  components: {
    dragFloat,
    WzDialog,
    Loading
  },
  props: {
    max: {
      type: Number,
      default: 1
    },
    title: {
      type: String,
      default: '可视对讲'
    }
  },
  data() {
    return {
      path: '',
      isProjectPage: false,
      imgCameraOn: imgCameraOn,
      imgCameraOff: imgCameraOff,
      imgMicOn: imgMicOn,
      imgMicOff: imgMicOff,
      defaultAvatar: require('../../assets/image/avter.png'),
      dialogPersonnelVisible: false,
      dialogVideoVisible: false,
      addressList: [],
      currentAddressList: [],
      checkedIds: [],
      checkedMax: 1,
      checkeds: [],
      checked: '0',
      teams: [],
      search: {
        teamId: '0',
        keywords: ''
      },
      trtcVideo: {
        colNum: 0,
        span: 0,
        itemHeight: '0%',
        isInit: false,
        cameras: [],
        cameraId: '',
        microphones: [],
        micId: '',
        trtcClient: null,
        shareClient: null,
        members: [],
        currentUser: null,
        userNum: 0,
        remoteNum: 0,
        userId: '0',
        roomId: 0,
        userSign: ''
      }
    }
  },
  watch: {
    $route(to, from) {
      this.path = to.path
      this.setIsProjectPage()
    },
    dialogPersonnelVisible(val) {
      if (val && (!hasVal(this.addressList))) {
        this.getAddressList()
      }
    },
    dialogVideoVisible(val) {
      if (val) {
        this.initVideo()
      }
    },
    max(val) {
      this.checkedMax = val
    },
    remoteStreams(val) {
      this.trtcVideo.remoteNum = val.length
    },
    'trtcVideo.members'(val) {
      this.trtcVideo.userNum = val.length
    },
    'trtcVideo.userNum'(val) {
      this.trtcVideo.colNum = Math.ceil(Math.sqrt(val))
      this.trtcVideo.span = Math.floor(24 / val)
      const row = Math.ceil(val / this.trtcVideo.colNum)
      this.trtcVideo.itemHeight = (100 / row) + '%'
    }
  },
  created() {
    this.path = location.pathname
    this.setIsProjectPage()
  },
  mounted() {
    this.checkedMax = this.max
  },
  methods: {
    initVideo() {
      const that = this
      if (!that.trtcVideo.isInit) {
        rtcDetection().then(detectionResult => {
          detectionResult
        })

        // setup logging stuffs
        TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.DEBUG)
        TRTC.Logger.enableUploadLog()

        TRTC.getDevices()
          .then(devices => {
            devices.forEach(item => {
              // console.log('device: ' + item.kind + ' ' + item.label + ' ' + item.deviceId)
            })
          })
          .catch(error => console.error('getDevices error observed ' + error))

        // populate camera options
        TRTC.getCameras().then(devices => {
          that.trtcVideo.cameras = []
          devices.forEach(device => {
            if (!that.trtcVideo.cameraId) {
              that.trtcVideo.cameraId = device.deviceId
            }
            that.trtcVideo.cameras.push({
              id: device.deviceId,
              label: device.label
            })
          })
        })

        // populate microphone options
        TRTC.getMicrophones().then(devices => {
          that.trtcVideo.microphones = []
          devices.forEach(device => {
            if (!that.trtcVideo.micId) {
              that.trtcVideo.micId = device.deviceId
            }
            that.trtcVideo.microphones.push({
              id: device.deviceId,
              label: device.label
            })
          })
        })
        that.trtcVideo.isInit = true
      }
    },
    onClick() {
      this.dialogPersonnelVisible = true
    },
    onSearch() {
      const that = this
      console.log(that.search.keywords)
      this.currentAddressList = this.addressList.filter(item => {
        const isHas = ((that.search.teamId === '0') || item.teamId === that.search.teamId) &&
        ((!hasVal(that.search.keywords)) || (item.name.indexOf(that.search.keywords) > -1 || item.teamName.indexOf(that.search.keywords) > -1))
        return isHas
      })
    },
    getAddressList() {
      getApproachList().then((res) => {
        const { data } = res
        this.addressList = []
        this.currentAddressList = []
        this.teams = []
        this.checkeds = []
        this.teams.push({
          id: '0',
          name: '所有班组'
        })
        data.items.forEach(item => {
          item.checked = false
          if (!inArray(this.addressList, a => {
            return a.outId === item.outId || a.id === item.id
          })) {
            this.addressList.push(item)
            this.currentAddressList.push(item)
          }
          if (!inArray(this.teams, 'id', item.teamId)) {
            this.teams.push({
              id: item.teamId,
              name: item.teamName
            })
          }
        })
      })
    },
    setChecked() {
      this.checkeds = []
      if (this.checkedMax > 1) {
        this.addressList.forEach(item => {
          item.checked = inArray(this.checkedIds, item.id)
          if (item.checked) {
            this.checkeds.push(item)
          }
        })
      } else {
        this.addressList.forEach(item => {
          item.checked = item.id === this.checked
          if (item.checked) {
            this.checkeds.push(item)
          }
        })
      }
    },
    onCheckbox(item) {
      if (this.checkedMax > 1) {
        if (this.checkedIds.length < this.checkedMax || item.checked) {
          item.checked = !item.checked
          if (item.checked) {
            this.checkedIds.push(item.id)
          } else if (inArray(this.checkedIds, item.id)) {
            this.checkedIds = this.checkedIds.filter(a => {
              return a !== item.id
            })
          }
          this.setChecked()
        }
      } else {
        item.checked = !item.checked
        this.checked = item.id
        this.setChecked()
      }
    },
    onChange() {
      this.setChecked()
    },
    onCall() {
      const that = this
      if (hasVal(that.checkedIds)) {
        call({
          receiverId: that.checkedIds
        }).then(async(response) => {
          const { data } = response
          that.trtcVideo.roomId = data.roomNum > 0 ? data.roomNum : 77677
          that.trtcVideo.userId = data.callerId
          that.trtcVideo.userSign = data.userSign

          const options = {
            mode: 'rtc', // 实时音视频通话模式，设置为‘videoCall’。
            sdkAppId: data.sdkAppId, // 您从腾讯云申请的 sdkAppId
            userId: that.trtcVideo.userId, // 用户ID不唯一的随机数，可自己写
            userSig: that.trtcVideo.userSign, // 身份签名，相当于登录密码的作
            roomId: that.trtcVideo.roomId,
            vue: that
          }
          that.trtcVideo.trtcClient = createTrtcClient({
            ...options,
            prefix: 'video_',
            controls: false,
            addMember: that.addMemberView,
            removeMember: that.removeMemberView,
            addVideo: that.addVideoView,
            changeAudioStatus: that.changeAudioStatus,
            changeVideoStatus: that.changeVideoStatus,
            loaded: that.loaded,
            reset: that.resetView,
            getCameraId: () => {
              return that.trtcVideo.cameraId
            },
            getMicrophoneId: () => {
              return that.trtcVideo.micId
            }
          })
          this.join()
          // that.trtcVideo.shareClient = createShareClient(options)
          that.dialogPersonnelVisible = false
          that.dialogVideoVisible = true
        })
      } else {
        that.$alert('请求选择参与人员', that.title)
      }
    },
    onLeave(done) {
      const that = this
      that.$confirm('确认结束通话？', that.title)
        .then(_ => {
          that.$nextTick(async() => {
            await that.trtcVideo.trtcClient.leave()
            this.resetTrtcVideo()
            if (isFunction(done)) {
              done()
            } else {
              this.dialogVideoVisible = false
            }
          })
        })
        .catch(_ => {})
    },
    onChangeVideoStatus(item) {
      if (item.isCamOn) {
        if (item.stream.muteVideo()) {
          item.isCamOn = false
        }
      } else {
        if (item.stream.unmuteVideo()) {
          item.isCamOn = true
        }
      }
    },
    onChangeAudioStatus(item) {
      if (item.isMicOn) {
        if (item.stream.muteAudio()) {
          item.isMicOn = false
        }
      } else {
        if (item.stream.unmuteAudio()) {
          item.isMicOn = true
        }
      }
    },
    join() {
      this.$nextTick(async() => {
        await this.trtcVideo.trtcClient.join()
      })
    },
    addMemberView(userId) {
    },
    removeMemberView(userId) {
      var index = this.trtcVideo.members.findIndex(item => {
        return item.userId === userId
      })
      if (index > -1) {
        this.trtcVideo.members.splice(index, 1)
      }
    },
    addVideoView(obj) {
      var index = this.trtcVideo.members.findIndex(item => {
        return item.userId === obj.userId
      })
      if (index === -1) {
        const userItem = this.addressList.find(item => {
          return item.outId === obj.userId
        })
        const user = {
          userId: obj.userId,
          name: obj.userId === this.trtcVideo.userId ? '我' : (hasVal(userItem) && hasVal(userItem.name) ? userItem.name : '未知'),
          stream: obj.stream,
          isCamOn: obj.stream.hasVideo(),
          isMicOn: obj.stream.hasAudio(),
          loading: true
        }
        this.trtcVideo.members.push(user)
        if (obj.userId === this.trtcVideo.userId) {
          this.trtcVideo.currentUser = user
        }
      }
    },
    changeAudioStatus(obj) {
      const user = this.getUser(obj.userId)
      if (typeof user !== 'undefined') {
        user.isMicOn = obj.status
      }
    },
    changeVideoStatus(obj) {
      const user = this.getUser(obj.userId)
      if (typeof user !== 'undefined') {
        user.isCamOn = obj.status
      }
    },
    loaded(obj) {
      if (hasVal(obj.userId) && hasVal(obj.event)) {
        this.loadedVideo(obj)
      }
    },
    loadedVideo(obj) {
      const user = this.getUser(obj.userId)
      if (typeof user !== 'undefined') {
        user.loading = false
        user.isCamOn = obj.stream.hasVideo()
        user.isMicOn = obj.stream.hasAudio()
      }
    },
    getUser(userId) {
      return this.trtcVideo.members.find(item => {
        return item.userId === userId
      })
    },
    resetView() {
      this.resetTrtcVideo()
      this.dialogVideoVisible = false
    },
    setIsProjectPage() {
      this.isProjectPage = this.path !== '/home' && this.path !== '/login' && this.path !== '/'
      if (this.isProjectPage) {
        this.initVideo()
      }
    },
    resetTrtcVideo() {
      this.trtcVideo.members = []
      this.trtcVideo.colNum = 0
      this.trtcVideo.span = 0
      this.trtcVideo.itemHeight = '0%'
      this.trtcVideo.userNum = 0
      this.trtcVideo.remoteNum = 0
      this.trtcVideo.roomId = 0
      this.trtcVideo.userSign = ''
      this.trtcVideo.trtcClient = null
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-body {
  margin-right: 16px;
}
.search-box {
  margin-bottom: 15px;
}
::v-deep .input-with-select {
  border: none;
  > *,
  > *:hover {
    border: 1px solid #00ffff;
    box-shadow: 0px 0px 15px rgba(0, 255, 255, 0.5) inset;
    background: rgba(0, 255, 255, 0.1);
    color: #fff;
  }
}
::v-deep .input-with-select:hover {
  border: none;
}
::v-deep .block {
  margin-bottom: 10px;
  position: relative;
  text-align: center;
  > * {
    cursor: pointer;
  }
  .el-checkbox {
    top: -2px;
    left: calc(50% - 23.5px);
  }
  .el-radio {
    top: 0px;
    left: calc(50% - 23.5px);
  }
  .el-checkbox,
  .el-radio {
    position: absolute;
    z-index: 100;
    .el-checkbox__input,
    .el-radio__input {
      .el-checkbox__inner,
      .el-radio__inner {
        border: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-image: url("../../assets/image/checkbox.png") !important;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .el-checkbox__inner::after,
      .el-radio__inner::after {
        display: none;
      }
    }
    .el-checkbox__input.is-checked,
    .el-radio__input.is-checked {
      .el-checkbox__inner,
      .el-radio__inner {
        border: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-image: url("../../assets/image/checkbox-checked.png") !important;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .el-checkbox__inner::after {
        display: none;
      }
    }
    .el-checkbox__label,
    .el-radio__label {
      display: none;
    }
  }
  .el-checkbox.is-disabled + div {
    cursor: not-allowed;
  }
  .avatar-wrapper {
    width: 47px;
    height: 62px;
    display: block;
    margin: 0 auto;
    .avatar {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid #0194a9;
      border-radius: 4px;
      width: 45px;
      height: 60px;
    }
  }
  .title,
  .team {
    color: #ffffff;
    font-size: 14px;
    line-height: 1.6;
  }
}
::v-deep .el-row-video {
  height: 100%;
  .el-col {
    .play-box {
      position: relative;
      width: 100%;
      height: 100%;
      border: 1px solid rgba(0, 255, 255, 0.5);
      box-shadow: 0px 0px 15px rgba(0, 255, 255, 0.5) inset;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      .not-video {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 16px;
        color: rgba(255, 0, 0, 0.7);
        width: 100%;
        height: 100%;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        z-index: 10;
        background: rgba(255, 255, 255, 0.1);
      }

      .video-tools {
        position: absolute;
        bottom: 0;
        z-index: 999;
        display: flex;
        width: 100%;
        height: 30px;
        background: rgba(255, 255, 255, 0.1);
        div.video-name {
          width: calc(100% - 80px);
          padding: 8px;
          line-height: 14px;
          color: #ffffff;
          text-align: left;
        }
        img {
          height: 30px;
          cursor: pointer;
        }
      }
    }
  }
}
::v-deep .dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-select {
    margin: 0 8px;
    .el-input {
      display: flex;
      align-items: left;
      justify-content: space-between;
      .el-input__inner {
        border: 1px solid #00ffff;
        box-shadow: 0px 0px 15px rgba(0, 255, 255, 0.5) inset;
        background: rgba(0, 255, 255, 0.1);
        padding-left: 70px;
        color: #fff;
      }
      .el-input__prefix {
        display: inline-block;
        left: 0;
        height: 100%;
        background: rgba(0, 255, 255, 0.2);
        color: #ffffff;
        line-height: 2.5;
        width: 65px;
        > * {
          margin-left: 8px;
          text-align: center;
        }
      }
    }
  }
}
::v-deep .dialog-footer.dialog-video-footer {
  height: 67px;
  .el-button {
    margin: 0 5px !important;
  }
}
</style>
