import Cookies from 'js-cookie'

const TokenKey = 'Owner-Token'
const ProjectKey = 'ProjectId'
const RolesKey = 'Role'
const OwnerInfokey = 'Info'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getProjectId() {
  return Cookies.get(ProjectKey)
}

export function saveProjectId(projectId) {
  return Cookies.set(ProjectKey, projectId)
}

export function removeProjectId() {
  return Cookies.remove(ProjectKey)
}

export function getItRoles() {
  return JSON.parse(sessionStorage.getItem(RolesKey))
}

export function saveRoles(roles) {
  return sessionStorage.setItem(RolesKey, JSON.stringify(roles))
}

export function removeRoles() {
  return sessionStorage.removeItem(RolesKey)
}

export function getOwner() {
  return JSON.parse(sessionStorage.getItem(OwnerInfokey))
}

export function saveOwner(info) {
  return sessionStorage.setItem(OwnerInfokey, JSON.stringify(info))
}

export function removeOwner() {
  return sessionStorage.removeItem(OwnerInfokey)
}
