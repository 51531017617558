<template>
  <div id="app" class="app">
    <transition name="el-fade-in">
      <router-view />
    </transition>
    <video-call :max="5" />
    <float />
  </div>
</template>

<script>
import float from '@/components/float'
import VideoCall from '@/components/video-call'
export default {
  name: 'App',
  components: {
    float,
    VideoCall
  }
}
</script>
