<template>
  <div v-drag class="drag-container" @click="onClick"><slot /></div>
</template>
<script>
export default {
  name: 'DragFloat',
  directives: {
    drag: {
      // 指令的定义
      bind: function(el, binding, vnode) {
        const odiv = el // 获取当前元素
        const clientWidth = document.documentElement.clientWidth
        const clientHeight = document.documentElement.clientHeight
        // 拖拽时间标识
        let firstTime = ''
        let lastTime = ''
        el.onmousedown = (e) => {
          odiv.setAttribute('drag-flag', false)
          firstTime = new Date().getTime()
          // 算出鼠标相对元素的位置
          // this.isDrag = false
          const disX = e.clientX - odiv.offsetLeft
          const disY = e.clientY - odiv.offsetTop
          const width = odiv.clientWidth + 6
          const height = odiv.clientHeight + 6
          let left = ''
          let top = ''
          document.onmousemove = (e) => {
            // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            left = e.clientX - disX
            top = e.clientY - disY
            // 绑定元素位置到positionX和positionY上面
            // 移动当前元素
            if (left > 0 && (left + width) < clientWidth) {
              odiv.style.left = left + 'px'
            }
            if (top > 0 && (top + height) < clientHeight) {
              odiv.style.top = top + 'px'
            }
            // 判断下当前时间与初始时间差，大于100毫秒则判断状态为拖拽
            lastTime = new Date().getTime()
            if (lastTime - firstTime > 100) {
              odiv.setAttribute('drag-flag', true)
            }
          }
          document.onmouseup = (e) => {
            document.onmousemove = null
            document.onmouseup = null
          }
          return false
        }
      }
    }
  },
  props: {
    // 滚动id
    scroller: {
      type: String,
      default: ''
    },
    // 距离上有下左的安全距离
    padding: {
      type: String,
      default: '10 10 10 10'
    },
    // 初始位置距离底部的距离
    bottom: {
      type: Number,
      default: 160
    }
  },
  data() {
    return {
      timer: null,
      currentTop: 0,
      clientWidth: 0,
      clientHeight: 0,
      itemWidth: 0,
      itemHeight: 0,
      left: null,
      top: null
    }
  },
  methods: {
    onClick(e) {
      const el = event.currentTarget
      const isDrag = el.getAttribute('drag-flag')
      if (isDrag !== 'true') {
        this.$emit('click')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.drag-container {
  position: absolute;
  height: 50px;
  width: 50px;
  padding: 10px;
  top: 70px;
  right: 20px;
  text-align: center;
  border: 3px solid #01929c;
  box-shadow: 0px 0px 15px rgba(11, 29, 65, 0.9) inset;
  background: rgba(0, 255, 255, 0.1);
  background-image: linear-gradient(
    rgba(11, 29, 65, 1) 1%,
    rgba(11, 29, 65, 0.2) 100%
  );
  border-radius: 50%;
  line-height: 1.2;
  text-align: center;
  cursor: pointer;
  color: #fefefe;
  z-index: 10;
  > div {
    i {
      font-size: 30px;
      margin: 0;
    }
    div {
      font-size: 12px;
    }
  }
}
.drag-container:hover {
  border: 3px solid #fad400 !important;
  box-shadow: 0px 0px 15px rgba(250, 212, 0, 0.9) inset !important;
  background-image: linear-gradient(
    rgba(250, 212, 0, 0.7) 1%,
    rgba(250, 212, 0, 0.2) 100%
  );
}
</style>
