<template>
  <div v-if="append" class="mask-wrapper" :style="`height:${wrapHeight}`">
    <div class="mask" :style="maskStyle">
      <dv-loading class="loading">拼命加载中...</dv-loading>
    </div>
  </div>
  <div v-else class="mask" :style="maskStyle">
    <dv-loading class="loading">拼命加载中...</dv-loading>
  </div>
</template>

<script>
export default {
  name: "Loading",
  components: {},
  props: {
    append: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    opacity: {
      type: Number,
      default: 0.8,
    },
  },
  data() {
    return {
      maskStyle: {
        width: "100vw",
        height: "100vh",
        position: "fixed",
      },
      wrapHeight: "100%",
    };
  },
  computed: {},
  watch: {
    append(val) {
      this.setStyle();
    },
    height(val) {
      this.wrapHeight = typeof val === "number" ? val + "px" : this.height;
    },
  },
  created() {
    this.wrapHeight =
      this.height === "number" ? this.height + "px" : this.height;
  },
  mounted() {
    this.setStyle();
    this.wrapHeight =
      typeof this.height === "number" ? this.height + "px" : this.height;
  },
  methods: {
    setStyle() {
      if (this.append) {
        this.maskStyle.width = "100%";
        this.maskStyle.height = "100%";
        this.maskStyle.position = "absolute";
      } else {
        this.maskStyle.width = "100vw";
        this.maskStyle.height = "100vh";
        this.maskStyle.position = "fixed";
      }
      this.maskStyle.backgroundColor = "rgba(40, 44, 52, " + this.opacity + ")";
    },
  },
};
</script>

<style lang='scss' scoped>
.mask-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.mask {
  background-color: rgba(40, 44, 52, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  .loading {
    color: #4bb8db;
  }
}
</style>
